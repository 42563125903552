export default {
  data () {
    return {
      page: 1,
      perPage: 15,
      perPageOptions: [15, 30, 50],
      totalResults: 0,
    };
  },
  computed: {
    totalPages () {
      if (this.totalResults === 0) {
        return 1;
      }

      if (this.perPage === 0) {
        return 1;
      }

      return Math.ceil(this.totalResults / this.perPage);
    },
    resultsInThisPage () {
      if (this.totalPages !== this.page) {
        return this.perPage;
      }

      return this.totalResults - ((this.page - 1) * this.perPage);
    },
  },
  methods: {
    fetchResults (page) {},
    parseMetadataFromResult (res) {
      return [
        parseInt((res?.headers || {})['x-pager-page'] || 1),
        parseInt((res?.headers || {})['x-pager-per-page'] || this.perPageOptions[0] || 15),
        parseInt((res?.headers || {})['x-pager-total-items'] || 0),
      ];
    },
    setMetadataFromHeaders (res) {
      this.page = res[0];
      this.perPage = res[1];
      this.totalResults = res[2];
    },
    setMetadata (data) {
      this.page = parseInt((data?.meta || {}).page || 1);
      this.perPage = parseInt((data?.meta || {})['per-page'] || this.perPageOptions[0] || 15);
      this.totalResults = parseInt((data?.meta || {})['total-items'] || 0);
    },
    goToPage (page) {
      this.fetchResults(page);
    },
  },
};
