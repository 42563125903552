export default (templateId) => {
  if (templateId === 1) {
    return 'Affiancamento orizzontale';
  }

  if (templateId === 2) {
    return 'Affiancamento verticale';
  }

  return '-';
};
