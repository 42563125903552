<template>
  <main class="page-projects page-show-project">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <router-link class="nav-link active" :to="{ name: 'modules.show' }" aria-current="page">Dati</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'modules.edit' }">Modifica</router-link>
      </li>
    </ul>
    <div class="container">
      <div class="mb-3 text-right" v-if="isAdmin">
        <button type="button" @click.prevent="removemodule" class="btn btn-outline-danger mr-2">Cancella</button>
      </div>

      <div class="table-responsive">
        <table class="table table-condensed">
          <tbody>
          <tr>
            <th>Progetto</th>
            <td>{{ project.name }}</td>
          </tr>
          <tr>
            <th>Nome livello</th>
            <td>{{ module.name }}</td>
          </tr>
          <tr>
            <th>Note</th>
            <td>{{ module.notes }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="d-flex flex-wrap justify-content-between align-items-center mt-4">
        <h2>Segnaposti<template v-if="points.length > 0"> ({{ totalResults }})</template></h2>

        <div class="mb-3 text-right">
          <router-link class="btn btn-outline-primary" :to="{ name: 'points.new' }">Aggiungi segnaposto</router-link>
        </div>
      </div>

      <template v-if="points && points.length > 0">
        <AwlTable striped :heading="tableHeadings" :rows="tableRows">
          <template #column-key-templateId="{ value }">{{ value | templateId }}</template>
          <template #column-key-colour="{ value }">
            <div class="colour-row">
              <ColouredSquare :colour="value | colour" /> <span>{{ value | colourName }}</span>
            </div>
          </template>

          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'points.show', params: { pointId: row.id } }" />
            <ActionEdit class="ml-2" :to="{ name: 'points.edit', params: { pointId: row.id } }" />
          </template>
        </AwlTable>

        <div class="row justify-content-end align-items-center">
          <div class="col-auto text-right">
            <p>
              Risultati da mostrare
              <PerPageSelector class="d-inline w-auto" v-model="perPage" :options="perPageOptions" @input="fetchResults(1)" />
            </p>
          </div>
        </div>
        <AwlPagination :value="page" :totalPages="totalPages" @page="goToPage" />
      </template>
      <Alert v-else status="info">Nessun segnaposto presente.</Alert>
    </div>
  </main>
</template>
<script>

import rolesMixin from '@/mixins/roles';
import confirmWithModalMixin from '@/libs/Confirm/mixins/confirmWithModal';
import pageableMixin from '@/libs/Pagination/mixins/pageable';
import $api from '@/libs/OAuth2/services/api';
import templateIdFilter from '@/filters/templateIdFilter';
import colourFilter, { colourNameFilter } from '@/filters/colourFilter';

export default {
  name: 'project-show',
  mixins: [rolesMixin, confirmWithModalMixin, pageableMixin],
  components: {
    AwlPagination: () => import('@/libs/Pagination/components/AwlPagination'),
    PerPageSelector: () => import('@/libs/Pagination/components/PerPageSelector'),
    Alert: () => import('@/libs/Feedback/components/Alert'),
    AwlTable: () => import('@/libs/Table/components/AwlTable'),
    ActionShow: () => import('@/libs/Table/components/Actions/ActionShow'),
    ActionEdit: () => import('@/libs/Table/components/Actions/ActionEdit'),
    ColouredSquare: () => import('@/views/components/Form/Widgets/ColouredSquare'),
  },
  filters: {
    templateId: templateIdFilter,
    colour: colourFilter,
    colourName: colourNameFilter,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      points: [],
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'windowTitle', label: 'Titolo' },
        { key: 'title', label: 'Sottotitolo' },
        { key: 'year', label: 'Anno' },
        { key: 'templateId', label: 'Template' },
        { key: 'colour', label: 'Colore' },
      ];
    },
    tableRows () {
      return (this.points || []);
    },
  },
  methods: {
    removemodule () {
      this.confirm('Cancellare il livello e tutti i segnaposti collegati?', 'Sì', 'No')
        .then(confirmed => {
          if (!confirmed) {
            return;
          }

          this.$api.removeModule(this.module.id)
            .then(() => {
              this.$router.replace({ name: 'projects.show' });
            })
            .catch(this.$log.errors)
          ;
        })
      ;
    },
    fetchResults (page = this.page) {
      return $api.listPoints(this.module.id, page, this.perPage)
        .then(res => {
          this.points = res?.data?.points || [];
          this.setMetadata(res?.data);
        })
        .catch(() => this.$log.error)
      ;
    },
  },
  mounted () {
    this.fetchResults();
  },
};

</script>

<style lang="scss">

.colour-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

</style>
