export default (color) => {
  if (color === 'primo') {
    return '#855BC3';
  }

  if (color === 'secondo') {
    return '#A56000';
  }

  if (color === 'terzo') {
    return '#1D79A1';
  }

  if (color === 'quarto') {
    return '#00ADA4';
  }

  if (color === 'quinto') {
    return '#EBD9CA';
  }

  if (color === 'sesto') {
    return '#E2D9EC';
  }

  return null;
};

export const colourNameFilter = (colour) => {
  if (colour === 'primo') {
    return 'Colore 1';
  }

  if (colour === 'secondo') {
    return 'Colore 2';
  }

  if (colour === 'terzo') {
    return 'Colore 3';
  }

  if (colour === 'quarto') {
    return 'Colore 4';
  }

  if (colour === 'quinto') {
    return 'Colore 5';
  }

  if (colour === 'sesto') {
    return 'Colore 6';
  }

  return '-';
};
